<template>
  <div class="d-flex align-center mb-4">
    <v-icon class="mr-1">mdi-playlist-check</v-icon>
    <span>{{ countMessage }}</span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          color="primary accent-2"
          class="ml-3"
          x-small
          @click="refresh"
          v-if="showRefresh"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>Atualizar lista</span>
    </v-tooltip>
    <v-spacer></v-spacer>
    <div>
      <v-pagination
        :total-visible="visiblePages"
        :value="currentPage"
        @input="pageSelected"
        :length="totalPages"
        v-if="totalPages > 1"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "Paginator",

  props: {
    visiblePages: {
      type: Number,
      default: 8,
    },
    totalItems: {
      type: Number,
    },
    totalPages: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    showRefresh: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    countMessage() {
      if (!isNaN(this.totalItems)) {
        switch (this.totalItems) {
          case 0:
            return "Nenhum registro foi encontrado";
          case 1:
            return "Foi encontrado 1 registro";
          default:
            return `Foram encontrados ${this.totalItems} registros`;
        }
      }

      return null;
    },
  },

  methods: {
    pageSelected(page) {
      this.$emit("update:currentPage", page);
      this.$emit("page", page);
    },

    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>