<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">
          Perfis
          <v-btn
            fab
            color="primary accent-2"
            x-small
            @click="addItem"
            v-auth="'PROFILE.ADD'"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-text>
            <Paginator
              v-bind.sync="profileList.pagingOptions"
              @page="pageSelected"
            ></Paginator>

            <v-data-table
              :headers="profileList.headers"
              :items="profileList.data"
              :items-per-page="25"
              :disable-sort="true"
              :loading="profileList.loading"
              no-data-text=""
              loading-text="Carregando dados..."
              class="app-table hover-table"
              @click:row="viewItem"
              hide-default-footer
            >
              <template v-slot:[`item.module`]="{ item }">
                {{ item.module_id | enum(data.moduleData) }}
              </template></v-data-table
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profileApi from "@/common/api/profile.api";
import moduleData from "@/common/data/module.data";
import Paginator from "@/components/layout/Paginator";

export default {
  name: "ProfileList",

  components: {
    Paginator,
  },

  data: () => ({
    data: {
      moduleData: moduleData,
    },

    profileList: {
      headers: [
        { text: "Nome", value: "name" },
        { text: "Módulo", value: "module" },
        { text: "Descrição", value: "description" },
      ],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
  }),

  created() {
    this.list();
  },

  methods: {
    pageSelected(page) {
      this.profileList.pagingOptions.currentPage = page;
      this.list();
    },

    addItem() {
      this.$router.push(`perfis/novo`);
    },

    list() {
      let filter = {
        page: this.profileList.pagingOptions.currentPage,
      };

      this.profileList.loading = true;
      profileApi.list(filter).then((result) => {
        this.profileList.loading = false;
        this.profileList.data = result.data.data;
        this.profileList.pagingOptions = result.data.pagingOptions;
      });
    },

    viewItem(item) {
      this.$router.push(`perfis/${item.id}`);
    },
  },
};
</script>